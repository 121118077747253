import React from 'react';
import { Message } from 'semantic-ui-react';

const NotFound = () => (
  <>
    <Message negative>
      <Message.Header>頁面不存在</Message.Header>
      <p>請檢查你的瀏覽器地址是否正確</p>
    </Message>
  </>
);

export default NotFound;
